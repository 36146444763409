<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Admin Registration</h2>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4">
                    <div class="main-body-div">
                        <b-form>
                            <div>
                                <p class="d-flex">This user will be the admin for the backoffice.</p>
                                <div class="auth-main mt-3">
                                    <div class="pb-2">
                                        <div class="error-block" v-if="(error.length !== 0)">
                                            <div class="error-block-img"> 
                                                <img src="../assets/images/error-icon.svg" />
                                            </div>
                                            <span>{{error}}</span>
                                        </div>
                                    </div>
                                        <div class="pt-2 pb-2">
                                            <label for="input-0" class="input-label">Display Name</label>
                                            <b-form-input
                                                id="input-0"
                                                type="text"
                                                placeholder="Display Name"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.displayName"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-1" class="input-label mt-3">Email address</label>
                                            <b-form-input
                                                id="input-1"
                                                type="email"
                                                placeholder="Email address"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.email"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <!-- <label for="input-2" class="input-label mt-3">Phone Number</label>
                                            <b-form-input
                                                id="input-2"
                                                type="text"
                                                placeholder="Phone Number"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.phone"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input> -->
                                            <label for="input-4" class="input-label mt-3">Password</label>
                                            <b-form-input
                                                id="input-4"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.password"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-5" class="input-label mt-3">Confirm Password</label>
                                            <b-form-input
                                                id="input-5"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.password2"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                        </div>
                                    <div class="btn-div">
                                        <button type="button" class="main-btn" v-on:click.prevent="submit()">Create New Admin Account
                                            <b-spinner small v-if="loading"></b-spinner>
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from './headDropdown.vue';
  import "firebase/firestore";
  import firebaseApp from "@/firebase";
  import {getFunctions, httpsCallable} from "firebase/functions";

  export default {

    name: 'RegisterAdmin',
    components: {
        HeadDropdown
    },
    data() {
        return {
            loading: false,
            error: "",
            userId: "",
            form: {
                displayName: "",
                email: "",
                phone: "",
                password: "",
                password2: ""
            },
        };
    },
    mounted() {
      this.userId = localStorage.getItem("userId");
    },
    methods: {
        formChanged: function() {
            this.error = ""
        },
        async submit() {
            this.loading = true
            const functions = getFunctions(firebaseApp);
            const createUser = httpsCallable(functions, 'createUser');
            await createUser({
                UserName: this.form.displayName,
                UserEmail: this.form.email,
                // UserPhone: this.form.phone,
                UserPassword: this.form.password,
                addAdmin: true
            }).then((result) => {
                this.res = result.data.mes;
                this.form = {
                    displayName: "",
                    email: "",
                    phone: "",
                    password: "",
                    password2: ""
                }
            })
            .catch(err => {
                this.loading = false;
                if(err.message.includes('phone number')) {
                    this.error = 'Phone number is not correct'

                } else if(err.message.includes('least 6 characters')){
                    this.error = 'Choose Strong password'
                } else if(err.message.includes('The email address is already in use')) {
                    this.error = 'Email already exist'
                } else {
                    this.error = 'Something went wrong'
                }
            })
            this.loading = false;
        },
    },
  }
</script>
  
<style scoped>
  .main-body-div{
    height: unset !important;
  }
  .input-label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
  }
  .btn-div{
    justify-content: space-around;
    flex-direction: unset !important;
  }
  .form-select {
    background-color: #f6f4f2 !important;
  }
</style>
import "firebase/auth";
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import {getAuth} from "firebase/auth";



// Dashboard
import Login from '../views/Login.vue';
import KYCTableView from '../views/KYCTableView.vue';
import ClientsTableView from '../views/ClientsTableView.vue';
import RegisterView from '../views/RegisterView.vue';
import DashboardViewQR from '../views/DashboardViewQR.vue';
import EditClientView from '../views/EditClientView.vue';
import ClientCreditsView from '../views/ClientCreditsView.vue';
import ClientSuccessView from '../views/ClientSuccessView.vue';
import ProfileView from '../views/ProfileView.vue';
import RegisterAdminView from '../views/RegisterAdminView.vue';

Vue.use(Router);


const router = new Router({
    mode: "history",
    routes: [
        {
            path: "*",
            redirect: "/login",
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/register',
            name: 'register',
            component: RegisterView,
            meta: {
                title: 'Register New Client | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/clients',
            name: 'clients',
            component: ClientsTableView,
            meta: {
                title: 'Clients Overview | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/kyc-database',
            name: 'kyc-database',
            component: KYCTableView,
            meta: {
                title: 'All Clients KYC Database | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/view-qr/:id',
            name: 'view-qr',
            component: DashboardViewQR,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/client-edit/:id',
            name: 'client-edit',
            component: EditClientView,
            meta: {
                title: 'Clients Edit | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/manage-credits/:id',
            name: 'manage-credits',
            component: ClientCreditsView,
            meta: {
                title: 'Clients Credits | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/success',
            name: 'success',
            component: ClientSuccessView,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: ProfileView,
            meta: {
                title: 'Profile | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
        {
            path: '/create-admin',
            name: 'create-admin',
            component: RegisterAdminView,
            meta: {
                title: 'Register Admin | BMC Backoffice', 
                requiresAuth: true,
                requiresAdmin: true
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.document.title = to.meta && to.meta.title ? to.meta.title : 'BMC Backoffice';
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
    const verifiedUser = to.matched.some((record) => record.meta.verifiedUser);

    // console.log({ to });
    if(verifiedUser) {
        console.log("verifiedUser if");
        if (store.state.verifiedUser) next();
        else {
            console.log("verifiedUser else");
            next("bmc");
        }
    }
    if (requiresAuth) {
        console.log("requiresAuth if");
        if (requiresAdmin) {
            console.log("requiresAdmin if");
            if (store.state.user.isAdmin) {
                console.log("requiresAdmin if L1");
                next();
            }
            else {
                console.log("requiresAdmin else L1");
                next("login");
            }
        } else {
            console.log("requiresAdmin else");
            if (currentUser) {
                console.log("currentUser if");
                if (to.name === "index") {
                    console.log("im here in index if");
                    // next();
                    // http://localhost:8080/?fileId=243
                    // if (to.query.fileId) next({name: "scan", query: {fileId: to.query.fileId, chain: to.query.chain}});
                    // else next("upload");
                    next("clients")
                } else {
                    console.log("im here in index else");
                    next();
                }
            } else {
                console.log("currentUser else");
                next("login");
            }
        }
    }
    else {
        console.log("requiresAuth else");
        next();
        // if (currentUser) next("clients");
        // else next();
    }

    // if (requiresAdmin && !store.state.user.isAdmin) next("upload");
    // else if (requiresAuth && !currentUser) next("login");
    // else if (!requiresAuth && currentUser) next("upload");
    // else {
    //   next();
    // }

    // console.log("look");
    // console.log(store.state.windowWidth);
    // console.log(store.state.sidebarHidden);

    if (store.state.windowWidth < 800 && !store.state.sidebarHidden) {
        store.commit("TOGGLE_SIDEBAR_HIDDEN");
    }
});

export default router;

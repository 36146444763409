<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Edit Profile</h2>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4">
                    <div class="main-body-div">
                        <b-form>
                            <div>
                                <div class="auth-main mt-3">
                                    <div class="pb-2">
                                        <div class="error-block" v-if="(error.length !== 0)">
                                            <div class="error-block-img"> 
                                                <img src="../assets/images/error-icon.svg" />
                                            </div>
                                            <span>Email or password is incorrect.</span>
                                        </div>
                                    </div>
                                    <div class="pt-2 pb-2">
                                        <label for="input-0" class="input-label">Display Name</label>
                                        <b-form-input
                                            id="input-0"
                                            type="text"
                                            placeholder="Display Name"
                                            class="form-fields mt-1"
                                            v-bind:class="{'input-error': error.length > 0}"
                                            v-model="form.name"
                                            v-on:change="formChanged"
                                        >
                                        </b-form-input>
                                        <label for="input-1" class="input-label mt-3">Email address</label>
                                        <b-form-input
                                            id="input-1"
                                            type="email"
                                            placeholder="Email address"
                                            class="form-fields mt-1"
                                            v-bind:class="{'input-error': error.length > 0}"
                                            v-model="form.email"
                                            v-on:change="formChanged"
                                        >
                                        </b-form-input>
                                        <!-- <label for="input-2" class="input-label mt-3">Phone Number</label>
                                        <VuePhoneNumberInput 
                                            :default-country-code="countryCode"
                                            v-model="form.phone"
                                            @update="onUpdate"
                                        /> -->
                                        <!-- <b-form-input
                                            id="input-2"
                                            type="text"
                                            placeholder="Phone Number"
                                            class="form-fields mt-1"
                                            v-bind:class="{'input-error': error.length > 0}"
                                            v-model="form.phone"
                                            v-on:change="formChanged"
                                        >
                                        </b-form-input> -->
                                        <hr />
                                        <div class="mt-4">
                                            <label for="input-4" class="input-label mt-3">Current Password</label>
                                            <b-form-input
                                                id="input-4"
                                                type="password"
                                                placeholder="********"
                                                description="Enter your full name"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="currentPassword"
                                                v-on:change="formChanged"
                                                @blur="checkPassword"
                                            >
                                            </b-form-input>
                                            <label class="text-muted input-label text-small">Leave blank for no change.</label>
                                            <label for="input-4" class="input-label mt-3">Password</label>
                                            <b-form-input
                                                id="input-4"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="password"
                                                v-on:change="formChanged"
                                                :disabled="!passwordApproved"
                                            >
                                            </b-form-input>
                                            <label for="input-5" class="input-label mt-3">Confirm Password</label>
                                            <b-form-input
                                                id="input-5"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="password2"
                                                v-on:change="formChanged"
                                                :disabled="!passwordApproved"
                                            >
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="btn-div">
                                        <button type="button" class="main-btn" @click="update()">Update
                                            <b-spinner small v-if="loading"></b-spinner>
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../components/headDropdown.vue';
  import "firebase/storage";
  import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword, updateProfile, updateEmail, updatePhoneNumber } from "firebase/auth";
  import VuePhoneNumberInput from "vue-phone-number-input";
  import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

  export default {

    name: 'Register',
    components: {
        HeadDropdown,
        VuePhoneNumberInput
    },
    data() {
        return {
            loading: false,
            error: "",
            userId: "",
            form: {
                name: "",
                email: "",
                phone: "",
            },
            passwordApproved: false,
            currentPassword: "",
            password: "",
            password2: "",
            countryCode: 'US',
            phoneNumber: '',
        };
    },
    mounted() {
      this.userId = localStorage.getItem("userId");
      const auth = getAuth();
      console.log('auth', auth.currentUser);
      const data = auth.currentUser;
      this.form.name = data.displayName;
      this.form.email = data.email;
      this.form.phone = data.phoneNumber;
    },
    methods: {
        formChanged: function() {
            this.error = ""
        },
        onUpdate (payload) {
            this.phoneNumber = payload.formattedNumber;
            this.countryCode = payload.countryCode;
        },
        async update() {
            this.loading = true;
            const auth = getAuth();
            // Confirm current password
            const user = auth.currentUser;

            if(this.password !== "") {
                // Validate new password and confirm password match
                if (this.password !== this.password2) {
                    alert('New password and confirm password do not match');
                    return;
                }
    
                // Update password
                try {
                    await updatePassword(user, this.password);
                    alert('Password updated successfully');
                } catch (error) {
                    console.error(error);
                    alert('Error updating password');
                }

            }

            if(this.form.name !== "" && this.form.name !== user.displayName) {
                await updateProfile(user, {displayName: this.form.name});
                localStorage.setItem('displayName', this.form.name);
                this.$root.$emit('displayNameUpdated', this.form.name);
            }

            if(this.form.email !== "" && this.form.email !== user.email) {
                await updateEmail(user, this.form.email);
                console.log('Email updated')
            }

            // if(this.form.phone !== "" && this.form.phone !== user.phoneNumber) {
            //     const phoneNumberUtil = PhoneNumberUtil.getInstance();
            //     try {
            //         const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(this.phoneNumber, this.countryCode);
            //         const formatedNumber = phoneNumberUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
            //         console.log('formatedNumber', formatedNumber)
            //         await updatePhoneNumber(user, formatedNumber);
            //         console.log('phone updated')
            //     } catch (e) {
            //         console.error('Invalid phone number:', e);
            //     }
            // }
            this.loading = false;
        },
        async checkPassword() {
            const auth = getAuth();
            const user = auth.currentUser;
            const credential = EmailAuthProvider.credential(user.email, this.currentPassword);
            try {
                reauthenticateWithCredential(user, credential).then(() => {
                    this.passwordApproved = true;
                })
                .catch((error) => {
                    this.passwordApproved = false;
                });
            } catch (error) {
                console.log('catch error', error);
                return;
            }
        }
    }
  }
</script>
  
<style scoped>
  .main-body-div{
    height: unset !important;
  }
  .input-label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
  }
  .btn-div{
    justify-content: space-around;
    flex-direction: unset !important;
  }
  .text-small {
    font-size: 10px;
  }
  ::v-deep .select-country-container {
    margin-right: 10px !important;
  }
  ::v-deep .input-tel__input, ::v-deep .country-selector__input {
    background: #F6F4F2 !important;
    border: 1px solid #F6F4F2 !important;
    border-radius: 5px !important;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05)) !important;
    color: #06212C !important;
  }
  ::v-deep .vue-phone-number-input {
    margin-top: 8px;
  }
</style>
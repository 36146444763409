<template>  
    <div class="head-dropdown">
        <b-dropdown split :text="displayName" class="">
            <!-- <b-dropdown-item href="#">Update S3 Keys</b-dropdown-item> -->
            <b-dropdown-item @click="signOut">Logout</b-dropdown-item>
        </b-dropdown>
    </div>    
</template>
  
<script>
  import {getAuth, signOut} from "firebase/auth";
  export default {
    name: 'HeadDropdown',
    data() {
      return {
        displayName: 'Admin'
      }
    },
    mounted() {
      const name = localStorage.getItem("displayName");
      this.displayName = name !== "" && name !== 'null' ? name : 'Admin';
      this.$root.$on('displayNameUpdated', (name) => {
        this.displayName = name !== "" && name !== 'null' ? name : 'Admin';
      });
    },
    methods: {
      signOut() {
        const auth = getAuth();
        signOut(auth).then(() => {
          localStorage.clear();
          this.$router.push({
            name: "login",
          });
        });
      },
    }
  }
</script>
  
<style scoped>
</style>
<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h3>Credit Management</h3>
                        <p class="pt-2">You can manage a clients credit balance from here. Every credit adjustment, either addition or removal, requires a log entry.</p>
                    </div>
                </div>
                <!---------------- header End----------------->
                <template v-if="!loading">
                    <div class="pt-4">
                        <p class="text-start"><strong>Current Balance:</strong> {{totalCredit}} credits</p>
                    </div>
                    <div class="table-responsive  pt-4 item-table">
                        <table class="table text-start" id="item-table">
                            <thead class="tableHeading">
                                <tr class="border-bottom">
                                    <th scope="col">ID</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Logged By</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            
                            <tbody v-if="this.creditHistory.length > 0" >
                                <tr class="t-row" :key="index" v-for="(item, index) in paginatedcreditHistory">
                                    <td scope="row">{{index+1}}</td>
                                    <td>{{item.date | formatDate}}</td>
                                    <td :class="item.type === 'Credit' ? 'green' : 'red'"><span v-if="item.type == 'Credit'">+</span><span v-else>-</span>{{item.amount}}</td>
                                    <td>{{item.status}}</td>
                                    <td>{{item.admin}}</td>
                                    <td role="button" @click="deleteLog(item.id)">
                                        <img src="./../assets/images/minus-circle.svg">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!--------------- Pegination area --------------->
                        <div class="pegi-main pt-5">
                            <div class="pegi-dropdown">
                                <b-dropdown toggle-class='customDropdown' variant='none' id="dropdown-1" :text="pageSize.toString()">
                                    <b-dropdown-item @click="sizeChanged(10)">10</b-dropdown-item>
                                    <b-dropdown-item @click="sizeChanged(25)">25</b-dropdown-item>
                                    <b-dropdown-item @click="sizeChanged(50)">50</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <b-pagination v-model="currentPage" :total-rows="creditHistory.length" :per-page="pageSize"></b-pagination>
                            <div class="pegi-search-num pegi-search">Results {{((currentPage*pageSize)-pageSize+1)}} - {{(currentPage*pageSize >= creditHistory.length ? creditHistory.length : (currentPage*pageSize))}} of {{creditHistory.length}}</div>
                        </div>
                    </div>
                    <div class="pt-4">
                        <button class="main-btn" role="button" v-b-modal="'creditModal'">
                            Add / Deduct Credit
                        </button>
                    </div>
                    <div class="cm-modal">
                        <b-modal size="lg" id="creditModal" class="cr-modal-main"  ref="creditModal" title="" header-class="cd-header" modal-class="mb-body">
                            <h3>Credit Management</h3>
                            <p class="cm-inner-title">Add / Deduct Credit</p>
                            
                            <form style="margin-top: 20px;" action="#" @submit.prevent="manageCredit()">
                                <div class="form-group row">
                                    <div class="row">
    
                                        <!-- Data -->
                                        <div class="col-md-6">
                                            <div class="cf-input-col">
                                                <label for="name" class="col-form-label text-md-right cl-input">Date</label>
                                                <b-form-datepicker id="example-datepicker" v-model="form.date" class="mb-2"></b-form-datepicker>
                                            </div>
                                        </div>
                                        <!-- Type -->
                                        <div class="col-md-6">
                                            <div class="cf-input-col">
                                                <label for="password" class=" col-form-label text-md-right cl-input">
                                                    Type
                                                </label>
                                                <b-form-select class="form-select selct-opt" v-model="form.type" >
                                                    <option value="Debit">
                                                        Debit
                                                    </option>
                                                    <option value="Credit">
                                                        Credit
                                                    </option>
                                                </b-form-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-0">
    
                                        <!-- Amount -->
                                        <div class="col-md-6">
                                            <div class="cf-input-col">
                                                <label for="name" class="col-form-label text-md-right cl-input">Amount</label>
                                                <input
                                                    type="number"
                                                    class="form-control inner-input"
                                                    name="amount"
                                                    value
                                                    required
                                                    autocomplete="off"
                                                    v-model="form.amount"
                                                />
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="text-center credit-btn-div">
                                        <button type="submit" class="btn dark-green-btn">Update
                                            <b-spinner small v-if="creditSubmitted"></b-spinner>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </b-modal>
                    </div>
                </template>
                <template v-if="loading">
                    <div class="main-body-div">
                        <div class="navbar-main">
                        <b-container class="pt-4 centered">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            <div>
                            <p class="font-12 text-center">Loading...</p>
                            </div>
                        </b-container>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../components/headDropdown.vue';
  import axios from 'axios';

  export default {

    name: 'ClientCredits',
    components: {
        HeadDropdown
    },
    data() {
        return {
            creditHistory: [],
            currentPage: 1,
            pageSize: 10,
            form: {},
            creditSubmitted: false,
            totalCredit: 0,
            loading: true,
            clientId: ''
        }
    },
    filters: {
        formatDate(date) {
            let date1 = new Date(date);
            return date1.toLocaleString("en-US", { day: "numeric", month: "long", year: "numeric" });
        }
    },
    mounted() {
        this.clientId = this.$route.params.id
        this.getData();
    },
    methods: {
        sizeChanged(size) {
            this.pageSize = size;
        },
        manageCredit() {
            this.creditSubmitted = true;
            const name =localStorage.getItem("displayName");
            const data = {
                amount: this.form.amount, 
                date: new Date(this.form.date), 
                type: this.form.type,
                clientId: this.clientId,
                admin: name !== "" && name !== 'null' ? name : 'BMC Admin'
            }
            axios.post('https://kycapi.aml-verify.com/addCreditHistory', data).then( (res) => {
                if(res.status === 200) {
                    this.creditSubmitted = false;
                    this.$refs.creditModal.hide();
                    this.getData();
                }
            }).catch((err) => {
                console.log('getClientByID err', err)
            });
        },
        getData() {
            console.log('getCreditHistory', this.clientId)
            axios.post('https://kycapi.aml-verify.com/getCreditHistory', {clientId: this.clientId}).then( (res) => {
                if(res.status === 200) {
                    console.log('getCreditHistory', res.data)
                    this.loading = false;
                    this.creditHistory = res.data.CreditHistory;
                    this.totalCredit = res.data.sum;
                }
            }).catch((err) => {
                console.log('getCreditHistory err', err)
            }); 
        },
        deleteLog(id) {
            let resp = confirm("Are you sure you want to delete this log and update the client balance?");
            console.log('confirm', resp);
            if(resp) {
                axios.post('https://kycapi.aml-verify.com/deleteCreditLog', {uid:id}).then( (res) => {
                    if(res.status === 200) {
                        console.log('getCreditHistory', res.data)
                        this.getData();
                    }
                });
            }
        }
    },
    computed: {
        paginatedcreditHistory() {
            const start = (this.currentPage - 1) * this.pageSize
            return this.creditHistory.slice(start, start + this.pageSize)
        }
    }
  }
</script>
  
<style scoped>
    .cm-inner-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #263853;
        padding: 10px 0 0 0;
    }
    .cl-input{
        font-size: 12px;
        color: #263853;
        font-weight: 700;
    }
    .inner-input{
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        border-radius: 3px;
        color: #484848;
    }
    thead.tableHeading {
        border: 1px solid #0796D0;
        background: #0796D0;
    }
    .tableHeading th {
        font-size: 12px;
        color: #ffff;
        font-weight: 700;
        border: 2px solid #ffff;
        text-align: center;
    }
    tr.t-row td {
        text-align: center;
    }
    .green{
        color: #2F7669;
    }
    .red {
        color: #FA415B;
    }
    .selct-opt {
        background: #FFFFFF;
        border: 1px solid #ced4da;
        border-radius: 5px;
        color: #484848;
        line-height: 2.4;
    }
    .inner-input {
        border-radius: 5px;
        border: 1px solid #ced4da;
        color: #484848;
        line-height: 1.6;
    }
    ::v-deep .page-link {
        color: #777777; 
        border: none !important;
        background-color: #fafafa !important;
        font-size: 15px !important;
    }
    ::v-deep .page-link.active, ::v-deep .active > .page-link {
        background-color: #0796D0 !important;
        border-color: #0796D0 !important;
        color: white;
        border-radius: 4px;
    }
    ::v-deep .page-link.disabled, ::v-deep .disabled > .page-link {
        color: #d1d0d0 !important;
    }
    ::v-deep .dropdown-toggle {
        color: #777777 !important; 
        padding: 0px 15px;
    }
    .btn-primary {
        background-color: #263853 !important;
        border-color: #263853 !important;
    }
    ::v-deep .modal-footer {
        display: none !important;
    }
</style>
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
<template>
  <div class="cam-main-dashboard">
    <!-- main body tag -->
    <div class="body-div">
      <div class="main-uploadDoc">
      <!---------------- header Start----------------->
        <div class="dashboard-header">
          <div class="heading-area">
            <HeadDropdown/>
            <h2>View BMC</h2>
          </div>
        </div>
        <template v-if="!loading">
          <!---------------- header End----------------->
          <div class="success-chip mt-3 mt-md-5 d-flex align-items-center" v-if="notify">
            <span class="me-2">
              <img src="../assets/images/green-tick-icon.svg" alt="icon" >
            </span>
            <p><b>Success!</b> BMC QR has been successfully located on the blockchain.</p>
            <span class="ml-auto" @click="notify = false" role="button">
              <img src="../assets/images/close-icon.svg" alt="icon" >
            </span>
          </div>

          <!---------------- Inner Heading ----------------->
          <div class="inner-heading mt-3 mt-md-5">
              <h4>Result</h4>
          </div>

          <!---------------- Inner Content ----------------->
          <div class="qr-info-table">
            <!-- Info Area Start -->
            <div class="qr-table-main">
                <div class="info-inner">
                    <p class="font_gray">Name</p>
                    <p class="font_Darkgray" v-if="data.firstname">{{data.firstname + ' ' + data.lastname}}</p>
                    <p class="font_Darkgray" v-else>{{data.verification_data.document.name.first_name + ' ' + data.verification_data.document.name.last_name}}</p>
                </div>

                <div class="info-inner">
                    <p class="font_gray">Phone</p>
                    <p class="font_Darkgray">{{data.phone}}</p>
                </div>

                <div class="info-inner">
                    <p class="font_gray">Country</p>
                    <p class="font_Darkgray">{{data.info.geolocation.country_name}}</p>
                </div>

                  <div class="info-inner">
                    <p class="font_gray">Date</p>
                    <p class="font_Darkgray">{{date | formatDate }}</p>
                </div>
            
                <div class="info-inner">
                    <button class="dowLic_btn">
                      <a :href=transaction target="_blank">View Transaction ID</a>
                    </button>
                </div>
            </div>
            <!-- Info Area End -->

            <!-- Document area -->
            <div class="qr-doc-area mt-3">
              <b-row>
                <b-col xl="4" lg="12" md="12" sm="12">
                  <div class="qrDoc-cols">
                    <div class="qrDoc-inner">
                      <div class="qr-head-area pb-2">
                        <h6>Photo ID Verification</h6>
                        <span class="qrInner_img">
                          <img class="" src="../assets/images/verified-tick.svg" alt="">
                        </span>
                      </div>
                      <div class="qr-img-area">
                        <div class="QrImg_wrap">
                          <img class="w100" :src="data.proofs.document.proof" alt="">
                        </div>
                      </div>
                      <!-- download div -->
                      <div class="qr-download pt-1">
                        <p role="button" class="blue_text" @click="downloadImage(data.proofs.document.proof)">Download</p>
                      </div>
                      <!-- Description div -->
                      <div class="qr-desrip pt-1">
                        <p class="Qr_Gray_text">
                          {{ data.verification_data.document.selected_type[0] === "id_card" ? 'ID Card' : data.verification_data.document.selected_type[0] === "passport" ? 'Passport' : 'Driver’s License' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col xl="4" lg="12" md="12" sm="12" v-if="data.proofs.document.additional_proof !== ''">
                  <div class="qrDoc-cols">
                    <div class="qrDoc-inner">
                      <div class="qr-head-area pb-2">
                        <h6>Photo ID Verification</h6>
                        <span class="qrInner_img">
                          <img class="" src="../assets/images/verified-tick.svg" alt="">
                        </span>
                      </div>
                      <div class="qr-img-area">
                        <div class="QrImg_wrap">
                          <img class="w100" :src="data.proofs.document.additional_proof" alt="">
                        </div>
                      </div>
                      <!-- download div -->
                      <div class="qr-download pt-1">
                        <p role="button" class="blue_text" @click="downloadImage(data.proofs.document.additional_proof)">Download</p>
                      </div>
                      <!-- Description div -->
                      <div class="qr-desrip pt-1">
                        <p class="Qr_Gray_text">
                          {{ data.verification_data.document.selected_type[0] === "id_card" ? 'ID Card' : data.verification_data.document.selected_type[0] === "passport" ? 'Passport' : 'Driver’s License' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col xl="4" lg="12" md="12" sm="12">
                  <div class="qrDoc-cols">
                    <div class="qrDoc-inner">
                      <div class="qr-head-area pb-2">
                        <h6>Face Verification</h6>
                        <span class="qrInner_img">
                          <img class="" src="../assets/images/verified-tick.svg" alt="">
                        </span>
                      </div>
                      <div class="qr-img-area">
                        <div class="QrImg_wrap">
                          <img class="w100" :src="data.proofs.face.proof" alt="">
                        </div>
                      </div>
                      <!-- download div -->
                      <div class="qr-download pt-1">
                        <p role="button" class="blue_text" @click="downloadImage(data.proofs.face.proof)">Download</p>
                      </div>
                      <!-- Description div -->
                      <div class="qr-desrip pt-1">
                        <p class="Qr_Gray_text">
                          {{data.verification_data.document.face_match_confidence}}%
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col xl="4" lg="12" md="12" sm="12" v-if="data.proofs.address">
                  <div class="qrDoc-cols">
                    <div class="qrDoc-inner">
                      <div class="qr-head-area pb-2">
                        <h6>Address Verification</h6>
                        <span class="qrInner_img">
                          <img class="" src="../assets/images/verified-tick.svg" alt="">
                        </span>
                      </div>
                      <div class="qr-img-area">
                        <div class="QrImg_wrap">
                          <img class="w100" :src="data.proofs.address.proof" alt="">
                        </div>
                      </div>
                      <div class="qr-download pt-1">
                        <p role="button" class="blue_text" @click="downloadImage(data.proofs.address.proof)">Download</p>
                      </div>
                      <div class="qr-desrip pt-1">
                        <p class="Qr_Gray_text">
                          {{data.verification_data.address.full_address}}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col xl="4" lg="12" md="12" sm="12">
                  <div class="qrDoc-cols">
                    <div class="qrDoc-inner">
                      <div class="qr-head-area pb-2">
                        <h6>Generated QR</h6>
                        <span class="qrInner_img">
                          <img class="" src="../assets/images/verified-tick.svg" alt="">
                        </span>
                      </div>
                      <div class="qr-img-area">
                        <div class="QrImg_wrap">
                          <img :src="qrImg" alt="">
                        </div>
                      </div>
                      <!-- download div -->
                      <div class="qr-download pt-1">
                        <p role="button" class="blue_text" @click="downloadImage(qrImg)">Download</p>
                      </div>
                      <!-- Description div -->
                      <div class="qr-desrip pt-1">
                        <p class="Qr_Gray_text">
                          Scan QR to download full verified document
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- button -->
            <div class="btn-div pt-5">
                <button role="button" class="main-btn btn-wide" @click="downloadBMC()"> Download Full Verified Document
                    <b-spinner small v-if="downloadStart"></b-spinner>
                </button>
            </div>
            <div class="pt-3">
                <p class="font-12Gray">
                    <b>Note:</b> 
                    This will download the full PDF document with all verified documents to a single file.
                </p>
            </div>
          </div>
          <!-- Upload Doc Bottom -->
          <!-- <div class="dashboard-bottom">
                <div class="tabs-btn d-flex justify-content-center">
                    <div class="">
                        <router-link to="dashboard-cam">
                            <button class="light-btn-blue2 ">
                                <span>
                                    <img src="../assets/images/qr-code-blue-icon.svg" alt="icon">
                                </span>
                                Scan Another BMC QR Code
                            </button>
                        </router-link>
                    </div>
                </div>
            </div> -->
        </template>
        <template v-if="loading">
          <div class="main-body-div">
              <div class="navbar-main">
              <b-container class="pt-4 centered">
                  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                  <div>
                  <p class="font-12 text-center">Loading...</p>
                  </div>
              </b-container>
              </div>
          </div>
        </template>
      </div>
    </div>
  </div>  
</template>
  
  
<script>
  import HeadDropdown from '../components/headDropdown.vue';
  import axios from 'axios';

  export default {
    
    name: 'ViewDoc',
    components: {
      HeadDropdown
    },
    data() {
      return {
        docId: '',
        loading: true,
        data: {},
        date: '',
        downloadStart: false,
        qrImg: '',
        qrImg64: '',
        docFront64: '',
        docBack64: '',
        face64: '',
        address64: '',
        notify: true,
        transaction: ''
      }
    },
    filters: {
      formatDate(date) {
          const d = new Date(
            date._seconds * 1000 + date._nanoseconds / 1000000
          )
          const options = {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
          }
          return d.toLocaleDateString('en-GB', options)
      }
    },
    mounted() {
        this.docId = this.$route.params.id;
        this.getData();
    },
    methods: {
      getFormattedDate(currDate) {
        const date = new Date(
          currDate._seconds * 1000 + currDate._nanoseconds / 1000000
        );
        let year = date.getFullYear()
        let month = ('0' + (date.getMonth() + 1)).slice(-2)
        let day = ('0' + date.getDate()).slice(-2)
        let hours = ('0' + date.getHours()).slice(-2)
        let minutes = ('0' + date.getMinutes()).slice(-2)
        return `${year}${month}${day}${hours}${minutes}`
      },
      getData() {
          axios.post('https://kycapi.aml-verify.com/shuftiTransaction', {
              reference: this.docId
          }).then(async (res) => {
            console.log('getData', res.data.documents)
              this.loading = false;
              if(res.status == 200) {
                this.date = res.data.date;
                this.qrImg = res.data.qrImage;
                this.data = res.data.documents;
                this.transaction = res.data.solTransaction;
              }
              
          }).catch((err) => {
            this.loading = false;
            console.log('shuftiStatus err', err)
          });
      },
      async downloadBMC() {
        this.downloadStart = true;
        const firstname = this.data.firstname ? this.data.firstname : this.data.verification_data.document.name.first_name;
        const lastname = this.data.lastname ? this.data.lastname : this.data.verification_data.document.name.last_name;
        const date = this.getFormattedDate(this.date);
        const filename = `BMC-QR-${date}-${firstname?.toUpperCase()}-${lastname?.toUpperCase()}.pdf`;
        axios.post('https://kycapi.aml-verify.com/generateFullBMCPDF', {
          reference: this.docId,
          filename: filename
        }, {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'arraybuffer'
        }).then(async (resp) => {
          const blob = new Blob([resp.data], {type: 'application/pdf'});
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = filename;
          link.click();
          axios.post('https://kycapi.aml-verify.com/removepdf', {
            file: filename
          }).then(async (resp) => {
            console.log('resp', resp)
            this.downloadStart = false;
          })
        });
      },
      async downloadImage(imageUrl) {
        try {
          const response = await axios.get(imageUrl+'?'+Math.random().toString(36).substring(2, 6), { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = Math.random().toString(36).substring(2, 15) + '.jpg';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
</script>
  
<style scoped>
  .dowLic_btn>a {
    text-decoration: none !important;
    color: #fdfdfd;
  }
  .success-chip{
    display:flex;
    align-items:center;
  }
  .success-chip span{
    display:inline-block;
  }
  .success-chip span:last-child{
    margin-left:auto;
  }
  .btn-div{
    justify-content: space-around;
    flex-direction: unset !important;
  }
  .w100 {
    width: 100%;
  }
    .main-uploadDoc .heading-area{
        border: none;
    }
    .bg-clr{
        background-color: #c4c4c4
    }
    .size{
        height: 171px;
        width: 174px;
    }
    .dowLic-btn {
    background: #263853;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    height: 50px;
    width: 90%;
    font-weight: 700;
    font-size: 14px;
    line-height: 18.23px;
    color: #FFFFFF;
   }
   .clr-lic{
    color: #0FA958;
    font-size: 8px;
    font-weight: 400;
   }
   .light-btn-blue2{
    width: 278px;
    height: 51.33px;
    background: rgba(63, 209, 255, 0.07);
    border: 1px solid #0796D0;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    color: #0796D0;
    }
</style>
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
<style scoped>
.top-heading {
  width: 100vw;
  background-color: #0796D0;
  color: white;
  padding: 1rem;
}
.section-ref{
  margin: 1rem;
  border: 1px solid #ecf7ef;
  background: #ecf7ef;
}
.section-ref-red{
  margin: 1rem;
  border: 1px solid #fdf4f4;
  background: #fdf4f4;
}
.section-GeneralData-table, .section-BrowserInfo-table, .section-GeoLocation-table, .section-VerificationDetails-table {
  font-size: 11px;
}
.section-proofs-table img {
  width: 250px !important;
}
.table-bordered tr, .table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered td {
  height: 30px;
}
.section-GeneralData .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-BrowserInfo .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-GeoLocation .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 25%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-VerificationDetails .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-RequestTimeLog .head{
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 30%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
.section-Proofs .head {
  margin: 1rem 0 0 1rem;
  border: 1px solid #ecf7ef;
  width: 20%;
  background-color: #0796D0;
  border-radius: 0 50px 0 0;
}
</style>
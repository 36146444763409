<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Client Registration</h2>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4">
                    <div class="main-body-div">
                        <b-form>
                            <div v-if="step1">
                                <h3 class="d-flex">Client Admin</h3>
                                <p class="d-flex">This user will be the main client admin for the account.</p>
                                <div class="auth-main mt-3">
                                    <div class="pb-2">
                                        <div class="pt-2 pb-2">
                                            <div class="error-block" v-if="(error.length > 0)">
                                                <div class="error-block-img"> 
                                                    <img src="../assets/images/error-icon.svg" />
                                                </div>
                                                <span>Email or password is incorrect.</span>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-6 py-0">
                                                    <label for="input-0" class="input-label">First Name</label>
                                                    <b-form-input
                                                        id="input-0"
                                                        type="text"
                                                        placeholder="First Name"
                                                        class="form-fields mt-1"
                                                        v-bind:class="{'input-error': error.length > 0}"
                                                        v-model="form.firstname"
                                                        v-on:change="formChanged"
                                                    >
                                                    </b-form-input>
                                                </div>
                                                <div class="col-6 py-0">
                                                    <label for="input-01" class="input-label">Last Name</label>
                                                    <b-form-input
                                                        id="input-01"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        class="form-fields mt-1"
                                                        v-bind:class="{'input-error': error.length > 0}"
                                                        v-model="form.lastname"
                                                        v-on:change="formChanged"
                                                    >
                                                    </b-form-input>
                                                </div>
                                            </div>
                                            <label for="input-1" class="input-label mt-3">Email address</label>
                                            <b-form-input
                                                id="input-1"
                                                type="email"
                                                placeholder="Email address"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.email"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-2" class="input-label mt-3">Phone Number</label>
                                            <b-form-input
                                                id="input-2"
                                                type="text"
                                                placeholder="Phone Number"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.phone"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-3" class="input-label mt-3">Company Name</label>
                                            <b-form-input
                                                id="input-3"
                                                type="text"
                                                placeholder="Company Name"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.companyname"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-4" class="input-label mt-3">Password</label>
                                            <b-form-input
                                                id="input-4"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.password"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-5" class="input-label mt-3">Confirm Password</label>
                                            <b-form-input
                                                id="input-5"
                                                type="password"
                                                placeholder="********"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.password2"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="btn-div">
                                        <button type="button" class="main-btn" v-on:click.prevent="next">Next
                                        </button>
                                    </div>
                                </div>
                            </div> 
                            <div v-if="!step1">
                                <h3 class="d-flex">Business Information</h3>
                                <p class="d-flex">Business address and information on client KYC page.</p>
                                <div class="auth-main">
                                    <div class="pb-2">
                                        <div class="pt-2 pb-2">
                                            <label for="input-6" class="input-label">Address line 1</label>
                                            <b-form-input
                                                id="input-6"
                                                type="text"
                                                placeholder="Address line 1"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.address1"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label for="input-7" class="input-label mt-3">Address line 2</label>
                                            <b-form-input
                                                id="input-7"
                                                type="text"
                                                placeholder="Address line 2"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.address2"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <div class="row mb-2">
                                                <div class="col-6 py-0">
                                                    <label for="input-8" class="input-label mt-3">City</label>
                                                    <b-form-input
                                                        id="input-8"
                                                        type="text"
                                                        placeholder="City"
                                                        class="form-fields mt-1"
                                                        v-bind:class="{'input-error': error.length > 0}"
                                                        v-model="form.city"
                                                        v-on:change="formChanged"
                                                    >
                                                    </b-form-input>
                                                </div>
                                                <div class="col-6 py-0">
                                                    <label for="input-9" class="input-label mt-3">State (Optional)</label>
                                                    <b-form-input
                                                        id="input-9"
                                                        type="text"
                                                        placeholder="State (Optional)"
                                                        class="form-fields mt-1"
                                                        v-bind:class="{'input-error': error.length > 0}"
                                                        v-model="form.state"
                                                        v-on:change="formChanged"
                                                    >
                                                    </b-form-input>
                                                </div>
                                            </div>
                                            <label for="input-10" class="input-label mt-3">Postal Code</label>
                                            <b-form-input
                                                id="input-10"
                                                type="text"
                                                placeholder="Postal Code"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.postalcode"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                            <label class="input-label mt-3">Country</label>
                                            <div class="radio-div-slct selct-field">
                                                <b-form-select class="form-select selct-opt" v-model="form.country" >
                                                    <option :value="null" disabled>Select Country</option>
                                                    <option v-for="option in options" :value="option.code">
                                                    {{ option.name }}
                                                    </option>
                                                </b-form-select>
                                            </div>
                                            <div id="app" class="my-3 login-chk-box">
                                                <b-form-checkbox
                                                id="checkbox-1"
                                                v-model="form.checked"
                                                size="sm"
                                                type="checkbox"
                                                >
                                                    <p class="font-12">
                                                        Send account registration details to user email
                                                    </p>
                                                </b-form-checkbox>  
                                            </div>
                                            <label for="input-11" class="input-label mt-3">BMC KYC URL</label>
                                            <b-form-input
                                                id="input-11"
                                                type="text"
                                                placeholder="https://kyc.ctech.co.uk"
                                                class="form-fields mt-1"
                                                v-bind:class="{'input-error': error.length > 0}"
                                                v-model="form.domain"
                                                v-on:change="formChanged"
                                            >
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="btn-div">
                                        <button class="main-btn" v-on:click.prevent="registerClient()">Create New User Account
                                            <b-spinner small v-if="loading"></b-spinner>
                                        </button>
                                    </div>
                                    <div class="mt-2">
                                        <button class="gray-btn ps-2" v-on:click.prevent="previous()">Previous Step
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../components/headDropdown.vue';
  import countries from "../assets/countries.json";
  import firebaseApp from '../firebase.js';
  import "firebase/storage";
  import {getFunctions, httpsCallable} from "firebase/functions";
  import "firebase/firestore";
  import axios from 'axios';

  export default {

    name: 'Register',
    components: {
        HeadDropdown
    },
    data() {
        return {
            step1: true,
            options: countries,
            loading: false,
            error: "",
            userId: "",
            form: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                companyname: "",
                password: "",
                password2: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                postalcode: "",
                country: null,
                domain: "",
                checked: false
            },
        };
    },
    mounted() {
      this.userId = localStorage.getItem("userId");
    },
    methods: {
        formChanged: function() {
            this.error = ""
        },
        async registerClient() {
            this.loading = true;
            const functions = getFunctions(firebaseApp);
            const createClient = httpsCallable(functions, 'createClient');
            const createDomain = httpsCallable(functions, 'createUserUrl');
            const checkDomainAvailability = httpsCallable(functions, 'checkDomainAvailability');
            const availability = await checkDomainAvailability({
                domain: this.form.domain
            })
            if (!availability.data) {
                this.loading = false
                console.log('Domain is already registered');
            } else {
                await createClient({
                    UserName: this.form.firstname + this.form.lastname,
                    UserEmail: this.form.email,
                    UserPassword: this.form.password,
                    parentUserId: this.userId,
                }).then(async (response) => {
                    await createDomain({
                        clientId: response.data.uid,
                        name: 'basic',
                        domain: this.form.domain
                    }).then((res) => {
                        console.log('createDomain', res)
                        delete(this.form.password);
                        delete(this.form.password2);
                        delete(this.form.checked);
                        this.form.status = "Active";
                        this.form.awsStatus = "Not Connected";
                        this.form.users = 0;
                        this.form.verifiedDocs = 0;
                        this.form.clientId = response.data.uid
                        console.log('Client created successfully');
                        axios.post("https://kycapi.aml-verify.com/addClientProfile", {
                           data: this.form
                        }).then(() => {
                            this.loading = false;
                            this.success = "Client created successfully";
                            this.$router.push({name: "success"});
                        }).catch(err => {
                            this.loading = false;
                            this.error = "Unable to created Client";
                        })
                    })
                }).catch(err => {
                    console.log('createClient catch', err)
                    this.error = err
                })
            }
            this.loading = false;
        },
        next() {
            this.step1 = false;
        },
        previous() {
            this.step1 = true;
        }
    },
  }
</script>
  
<style scoped>
  .main-body-div{
    height: unset !important;
  }
  .input-label {
    display: flex;
    font-weight: 600;
    font-size: 14px;
  }
  .btn-div{
    justify-content: space-around;
    flex-direction: unset !important;
  }
  .form-select {
    background-color: #f6f4f2 !important;
  }
</style>
<template>
    <div class="main-dashboard">
        <div id="wrap-main">
          <div>
              <DashboardNav/>
          </div>
          <div>
            <EditClient />
          </div>
            
        </div>
    </div>
    
  </template>
  
  
  <script>
  
  import DashboardNav from '../components/DashboardNav.vue';
  import EditClient from '../components/EditClient.vue';

  export default {
    
    name: 'EditClientView',
    components: {
      DashboardNav,
      EditClient
    },
  }
  </script>
  
  <style scoped>
  
  </style>
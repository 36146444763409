import Vue from "vue";
import VueResource from 'vue-resource';
import App from "./App.vue";
import router from "./router/index.js";
import vmodal from 'vue-js-modal';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import store from "./store/index.js";
import Vuetify from "vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import wb from "./registerServiceWorker";
import VueQrcodeReader from "vue-qrcode-reader";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Vuelidate from 'vuelidate'
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import './assets/main.css';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.use(VueResource);
Vue.use(VueToast);
Vue.use(vmodal)
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
require('firebase/storage')

Vue.use(VueQrcodeReader);
Vue.prototype.$workbox = wb;
Vue.use(Vuetify);
Vue.config.productionTip = false;
let app;
Vue.http.headers.common['Access-Control-Request-Method'] = "*";
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    store.dispatch("userAuthStateChanged", user);
    if (!app) {
        app = new Vue({
            router,
            vuetify: new Vuetify(),
            store,
            // QrReader,
            render: (h) => h(App),
        }).$mount("#app");
    }
});
<template>
    <div class="cam-main-dashboard">

        <!-- main body tag -->
        <div class="body-div">
            <div class="main-uploadDoc">

                <!---------------- header Start----------------->
                <div class="dashboard-header">
                    <div class="heading-area">
                        <HeadDropdown/>
                        <h2>Clients Overview</h2>
                    </div>
                </div>
                <!---------------- header End----------------->


                <!-- Inner body tag -->
                <div class="pt-4" v-if="!loading">
                    <div class="input-group rounded position-relative">
                        <input v-model="searchText" type="search" class="form-control rounded ps-5 bg" placeholder="Search the user database" aria-label="Search" aria-describedby="search-addon" />
                        <img src="./../assets/images/search.svg" class="position-absolute pt-3 ps-3 zIndex" alt="">
                    </div>
                    <div class="table-responsive  pt-4 user-table">
                        <table class="table text-start" id="user-table">
                            <thead class="tableHeading">
                                <tr class="border-bottom">
                                    <th scope="col">ID</th>
                                    <th scope="col">Registered</th>
                                    <th scope="col">Client Name</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Email Address</th>
                                    <th scope="col">Users</th>
                                    <th scope="col">Verified Documents</th>
                                    <th scope="col">Current Balance</th>
                                    <th scope="col">BMC URL</th>
                                    <th scope="col">AWS Status</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            
                            <tbody v-if="this.users.length > 0" >
                                <tr class="t-row" :key="index" v-for="(user, index) in paginatedUsers">
                                    <td scope="row">{{user.clientId.substring(0,6)}}</td>
                                    <td>{{user.date}}</td>
                                    <td>{{user.firstname + ' ' + user.lastname}}</td>
                                    <td>{{user.companyname}}</td>
                                    <td>{{user.email}}</td>
                                    <td>{{user.users}}</td>
                                    <td>{{user.verifiedDocs}}</td>
                                    <td>{{user.credit}}</td>
                                    <td>{{user.domain}}</td>
                                    <td>{{user.awsStatus}}</td>
                                    <td>{{user.status}}</td>
                                    <td>
                                        <div class="head-dropdown">
                                            <b-dropdown no-caret>
                                                <template #button-content>
                                                    <img src="./../assets/images/action.svg">
                                                </template>
                                                <!-- <b-dropdown-item>Copy Script</b-dropdown-item> -->
                                                <b-dropdown-item >
                                                    <router-link :to="{ path: '/client-edit/' + user.clientId }">
                                                        Edit Client
                                                    </router-link>
                                                </b-dropdown-item>
                                                <b-dropdown-item v-if="user.awsStatus !== 'Connected'" v-b-modal="'s3bucketModal'" @click="sendInfo(user.clientId)">Connect S3 Bucket</b-dropdown-item>
                                                <!-- <b-dropdown-item v-if="user.awsStatus === 'Connected'" v-b-modal="'s3bucketModal'" @click="updateS3Keys(user.clientId)">Update S3 Bucket</b-dropdown-item> -->
                                                <b-dropdown-item>
                                                    <router-link :to="{ path: '/manage-credits/' + user.clientId }">
                                                        Manage credits
                                                    </router-link>
                                                </b-dropdown-item>
                                                <b-dropdown-item v-if="user.status === 'Active'" @click="toggleClientStatus(user.clientId, 'Inactive')">Disable Account</b-dropdown-item>
                                                <b-dropdown-item v-if="user.status !== 'Active'" @click="toggleClientStatus(user.clientId, 'Active')">Enable Account</b-dropdown-item>
                                            </b-dropdown>
                                        </div>  
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--------------- Pegination area --------------->
                    <div class="pegi-main pt-5">
                        <div class="pegi-dropdown">
                            <b-dropdown toggle-class='customDropdown' variant='none' id="dropdown-1" :text="pageSize.toString()">
                                <b-dropdown-item @click="sizeChanged(10)">10</b-dropdown-item>
                                <b-dropdown-item @click="sizeChanged(25)">25</b-dropdown-item>
                                <b-dropdown-item @click="sizeChanged(50)">50</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <b-pagination v-model="currentPage" :total-rows="filteredUsers.length" :per-page="pageSize"></b-pagination>
                        <div class="pegi-search-num pegi-search">Results {{((currentPage*pageSize)-pageSize+1)}} - {{(currentPage*pageSize >= filteredUsers.length ? filteredUsers.length : (currentPage*pageSize))}} of {{filteredUsers.length}}</div>
                    </div>
                   
                </div>
                <template v-if="loading">
                    <div class="main-body-div">
                        <div class="navbar-main">
                        <b-container class="pt-4 centered">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            <div>
                            <p class="font-12 text-center">Loading...</p>
                            </div>
                        </b-container>
                        </div>
                    </div>
                </template>
                <b-modal id="s3bucketModal"  ref="s3bucketModal" title="Connect S3 Bucket">
                    <form style="margin-top: 20px;" action="#" @submit.prevent="addS3Keys()">
                        <div class="form-group row">
                            <label for="name" class="col-md-4 col-form-label text-md-right">Access Key</label>

                            <div class="col-md-6">
                                <input
                                    id="name"
                                    class="form-control"
                                    name="name"
                                    value
                                    required
                                    autocomplete="off"
                                    v-model="form.key"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="password" class="col-md-4 col-form-label text-md-right">Access Secret</label>

                            <div class="col-md-6">
                                <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    value
                                    required
                                    autocomplete="off"
                                    v-model="form.secret"
                                />
                            </div>
                        </div>
                        <div class="form-group row mb-0">
                        <div class="col-md-8 offset-md-4">
                            <button type="submit" class="btn btn-primary">Save
                                <b-spinner small v-if="s3Submitted"></b-spinner>
                            </button>
                        </div>
                        </div>
                    </form>
                </b-modal>
            </div>
        </div>
    </div>

</template>
  
  
<script>
  import HeadDropdown from '../components/headDropdown.vue';
  import axios from 'axios';
  
  export default {
    name: 'ClientTable',
    components: {
        HeadDropdown
    },
    mounted() {
        this.getUsers();
    },
    data() {
        return {
            searchText: '',
            currentPage: 1,
            pageSize: 10,
            users: [],
            loading: true,
            form: {},
            currClientId: '',
            s3Submitted: false
        }
    },
    computed: {
        filteredData() {
            // filter the data based on the search query
            console.log('users', this.users)
            return this.users.filter(user => {
                return user.firstname?.toLowerCase().includes(this.searchText?.toLowerCase()) || user.lastname?.toLowerCase().includes(this.searchText?.toLowerCase()) || user.email?.toLowerCase().includes(this.searchText?.toLowerCase());
            }).sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                
                return dateB - dateA;
            });
        },
        pageCount() {
            return Math.ceil(this.total / this.pageSize);
        },
        filteredUsers() {
            return this.users.filter(user => {
                return user.firstname?.toLowerCase().includes(this.searchText?.toLowerCase()) || user.lastname?.toLowerCase().includes(this.searchText?.toLowerCase()) || user.email?.toLowerCase().includes(this.searchText?.toLowerCase());
            }).sort((a, b) => {
                const dateA = new Date(a.date.split("/").reverse().join("-"));
                const dateB = new Date(b.date.split("/").reverse().join("-"));
                
                return dateB - dateA;
            });
        },
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.pageSize
            return this.filteredUsers.slice(start, start + this.pageSize)
        }
    },
    watch: {
        searchText(newValue, oldValue) {
            // update the paginated data when the search query changes
            this.currentPage = 1; // reset the current page to 1
            return this.filteredData.slice(0, this.pageSize);
        },
        currentPage(newValue, oldValue) {
            // update the paginated data when the current page changes
            const startIndex = (newValue - 1) * this.pageSize;
            return this.filteredData.slice(startIndex, startIndex + this.pageSize);
        }
    },
    methods: {
        getUsers() {
            // https://aml-verification.herokuapp.com
            // https://kycapi.fineit.io
            // https://kycapi.aml-verify.com
            this.loading = true
            axios.get('https://kycapi.aml-verify.com/getClientProfiles', {
            }).then( (res) => {
                if(res.status === 200) {
                    this.users = res.data;
                    this.loading = false
                }
            }).catch((err) => {
                console.log('getClientUsers err', err)
            });
        },
        sizeChanged(size) {
            this.pageSize = size;
        },
        sendInfo(item) {
            this.currClientId = item;
        },
        async addS3Keys() {
            try {
                this.s3Submitted = true;
                await axios.post("https://kycapi.aml-verify.com/s3/create-credentials", {
                    clientId: this.currClientId,
                    access_key: this.form.key,
                    access_secret: this.form.secret
                });
                this.$refs.s3bucketModal.hide();
                this.s3Submitted = false;
                this.getUsers();
            } catch (error) {
              console.log('error', error)  
            }
        },
        async updateS3Keys(id) {
            try {
                this.s3Submitted = true;
                await axios.post("https://kycapi.aml-verify.com/s3/update-credentials", {
                    clientId: id,
                    access_key: this.form.key,
                    access_secret: this.form.secret
                });
                this.getUsers();
                this.$refs.s3bucketModal.hide();
                this.s3Submitted = false;
            } catch (error) {
              console.log('error', error)  
            }
        },
        async toggleClientStatus(id, status) {
            try {
                await axios.post("https://kycapi.aml-verify.com/toggleClientStatus", {
                    clientId: id,
                    status
                }).then( (res) => {
                    console.log('res', res)
                    if(res.status === 200) {
                        this.getUsers();
                    }
                }).catch((err) => {
                    console.log('toggleClientStatus err', err)
                });
            } catch (error) {
                console.log('toggleClientStatus error', error)
            }
        }
    }
  }
</script>
  
<style scoped>
    .border-bottom{
        border-bottom: 1px solid hsl(0, 0%, 90%) !important;
    }
    .main-uploadDoc .heading-area{
        border: none;
    }
    td{
        padding: 1rem 0.5rem 0 !important;
        font-size: 12px;
        font-weight: 500;
        color: #656565;
    }
    ::v-deep .page-link {
        color: #777777; 
        border: none !important;
        background-color: #fafafa !important;
        font-size: 15px !important;
    }
    ::v-deep .page-link.active, ::v-deep .active > .page-link {
        background-color: #0796D0 !important;
        border-color: #0796D0 !important;
        color: white;
        border-radius: 4px;
    }
    ::v-deep .page-link.disabled, ::v-deep .disabled > .page-link {
        color: #d1d0d0 !important;
    }
    ::v-deep .dropdown-toggle {
        color: #777777 !important; 
        padding: 0px 15px;
    }
    .btn-primary {
        background-color: #263853 !important;
        border-color: #263853 !important;
    }
    ::v-deep .modal-footer {
        display: none !important;
    }
</style>
<style scoped>
  .centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0796D0 #ccc #ccc #ccc;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
<template>
    <div class="main-dashboard">
        <div id="wrap-main">
          <div>
            <DashboardNav/>
          </div>
          <div>
            <ClientCredits />
          </div>
            
        </div>
    </div>
    
  </template>
  
  
  <script>
  
  import DashboardNav from '../components/DashboardNav.vue';
  import ClientCredits from '../components/ClientCredit.vue';

  export default {
    name: 'ClientsTableView',
    components: {
      DashboardNav,
      ClientCredits
    },
  }
  </script>
  
  <style scoped>
  
  </style>
<template>
  <div class="sidebar" :class="sidebarHidden ? 'hidden-sidebar' : 'wide-sidebar'" v-if="user.loggedIn">
    <div>
      <div
          v-if="!sidebarHidden"
          class="d-flex menu-container"
          style="
          justify-content: space-between;
          align-items: center;
          padding-bottom: 157px;
        "
      >
        <img src="../static/bmclightlogo.png" width="103px" max-width="103px" height="30px"/>
        <button @click="hide" style="width: 30px; height: 30px">
          <img src="../static/closemenu.png" class="nice-icon"/>
        </button>
      </div>
      <div v-else class="menu-container" style="padding-bottom: 99px">
        <div
            style="
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 8px;
          "
        >
          <img src="../static/smallBMClogo.png" height="30px"/>
        </div>
        <div class="small-element">
          <button @click="hide">
            <img src="../static/menu.png" class="nice-icon"/>
          </button>
        </div>
      </div>

      <div class="menu-container" v-if="user.loggedIn">
        <div v-if="$store.state.user.isAdmin">
          <div :class="sidebarHidden ? 'small-element':'menu-element'">
            <router-link to="register"
            ><img src="../static/add-user.png" height="30px" width="30px"
                  style="padding-bottom: 5px; padding-right: 5px"/><span v-if="!sidebarHidden" style="margin-left: 15px;">Register</span>
            </router-link>
          </div>
          <div :class="sidebarHidden ? 'small-element':'menu-element'">
            <router-link to="clients">
              <img src="../static/group.png"/><span v-if="!sidebarHidden" style="margin-left: 17px;">Clients</span>
            </router-link>
          </div>
          <div :class="sidebarHidden ? 'small-element':'menu-element'">
            <router-link to="end-users">
              <img src="../static/costumer.png" style="width: 30px;"/><span v-if="!sidebarHidden" style="margin-left: 17px;">Client Users</span>
            </router-link>
          </div>
        </div>
        <template v-if="sidebarHidden">
          <div class="small-element">
            <router-link to="upload"><img src="../static/upload.png" class="nice-icon"/></router-link>
          </div>
          <div class="small-element">
            <router-link to="Scan"><img src="../static/scanbmc.png" class="nice-icon"/></router-link>
          </div>
          <div class="small-element">
            <router-link :to="user.data.role === 'client' ? 'client-history':'history'">
              <img src="../static/history.png"
                   class="nice-icon"/>
            </router-link>
          </div>
          <div class="small-element" v-if="user.data.role === 'client'">
            <router-link to="all-url"><img src="../static/url.png" class="nice-icon"/></router-link>
          </div>
        </template>
        <template v-else>
          <div class="menu-element">
            <router-link to="upload"><img src="../static/upload.png" class="nice-icon" style="margin-right:10px"/>
              Upload new file
            </router-link>
          </div>
          <div class="menu-element">
            <router-link to="Scan"><img src="../static/scanbmc.png" class="nice-icon" style="margin-right:10px"/> Scan
              BMC QR code
            </router-link>
          </div>
          <div class="menu-element" v-if="user.data.role === 'client'">
            <router-link to="client-history">
              <img src="../static/history.png" class="nice-icon"
                   style="margin-right:10px"/>
              History
            </router-link>
          </div>
          <div class="menu-element" v-if="user.data.role !== 'client'">
            <router-link to="history"><img src="../static/history.png" class="nice-icon" style="margin-right:10px"/>
              History
            </router-link>
          </div>
          <div v-if="user.data.role === 'client'" class="menu-element">
            <router-link to="add-url"><img src="../static/url.png" class="nice-icon" style="margin-right:10px"/>
              Register Url
            </router-link>
          </div>
        </template>
      </div>


      <!-- <div class="menu-container" v-else>
        <router-link to="login"
          ><img src="../static/account-plus.png" class="nice-icon"
        /></router-link>
        <div v-if="sidebarHidden" class="small-element">
          <router-link to="register"
            ><img src="../static/account-plus.png" class="nice-icon"
          /></router-link>
        </div>
        <div v-else class="menu-element">
          <router-link to="register"
            ><img
              src="../static/account-plus.png"
              class="nice-icon"
            />Register</router-link
          >
        </div>
      </div> -->
    </div>
    <div class="menu_container" v-if="user.loggedIn">
      <div v-if="sidebarHidden" class="small-element">
        <a @click.prevent="signOut"><img src="../static/logout.png" class="nice-icon"/></a>
      </div>
      <div v-else class="menu-element">
        <a @click.prevent="signOut"><img src="../static/logout.png" class="nice-icon"/>Log out</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  /* Stay in place */
  z-index: 1; /* Stay on top */
  /* background-image: linear-gradient(rgba(42,171,243,1),rgba(20,130,188,1)); */
  /* background-color: #ffffff; Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  /* background-image: linear-gradient(189.27deg, #2AABF3 -5.47%, #1482BC 103.62%),url("../static/back.png"); */
  box-sizing: border-box;
  background-position: 100% 100%;
  background-size: cover;
  background-image: linear-gradient(189.27deg, rgba(42, 171, 243, 1), rgba(20, 130, 188, 0.8)), url("../static/back.png");
}

.hidden-sidebar {
  width: 70px !important;
  min-width: 70px !important;
  padding: 45px 8px 45px 8px;
}

.wide-sidebar {
  width: 318px !important;
  min-width: 318px !important;
  padding: 45px 37px 45px 51px;
  @media only screen and (max-width: 799px) {
    width: 100vw !important;
    min-width: 100vw !important;
  }
}

.menu-container {
  width: 100%;
}

.menu-element {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-decoration: none !important;
  color: white !important;
  height: 50px;
  width: 100%;
  padding: 13px 0px 13px 25px;
  display: flex;
  align-items: center;
}

.menu-element a {
  text-decoration: none !important;
  color: white;
}

.nice-icon {
  height: 30px;
  width: 30px;
}

.small-element {
  font-size: 16px;
  display: block;
  height: 50px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import {mapGetters} from "vuex";
import firebase from "firebase/app";
import {getAuth, signOut} from "firebase/auth";

export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      sidebarHidden: "sidebarHidden",
    }),
  },
  methods: {
    signOut() {
      const auth = getAuth();
      signOut(auth)
          .then(() => {
            localStorage.clear();
            this.$router.push({
              name: "login",
            });
          });
    },
    hide() {
      this.$store.commit("TOGGLE_SIDEBAR_HIDDEN");
    },
  },
};
</script>
